import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        login: "Login",
        forgot_password: "Forgot Password?",
      },
    },
    ma: {
      translation: {
        Login: "Log Masuk Pengguna",
        "Forgot password": "Lupa kata laluan?",
        "Welcome To": "Selamat datang ke",
        "Terms and conditions": "Terma dan syarat",
        "and the": "dan juga",
        "Privacy Policy": "Dasar Privasi",
        "Accept & continue": "Terima & teruskan",
        "Select preferred language": "Pilih bahasa pilihan",
        Next: "Seterusnya",
        "Let's register": "Jom daftar",
        "Don't have an account?": "Tiada akaun?",
        "Sign up": "Daftar",
        "Signup Via Mobile": "Daftar melalui telefon bimbit",
        "Login Via Mobile": "Log masuk melalui telefon bimbit",
        "Using mobile number": "Nombor telefon",
        "Using username": "Nama pengguna",
        "Sign in": "Log masuk",
        Username: "Nama pengguna",
        "Please agree to terms and conditions.":
          "Sila bersetuju dengan terma dan syarat",
        "Enter ": "Masuk ",
        " here": " sini",
        "Enter username here": "Masukkan Nama Pengguna di sini",
        "Enter password here": "Masukkan kata laluan di sini",
        Password: "Kata Laluan",
        Login: "Log masuk",
        "Forgot Password?": "Lupa kata laluan?",
        "Login using": "Log masuk menggunakan",
        "Login via mobile": "Log masuk menggunakan",
        "You have not signed in before or have since signed out":
          "Anda belum daftar masuk sebelum ini atau telah log keluar",
        "User Cancelled Login": "Pengguna membatalkan Log Masuk",
        "Allow the permission to proceed.": "Kebenarkan untuk teruskan",
        "Please enter username to continue":
          "Sila masukkan nama pengguna untuk terus",
        "Please enter password to continue":
          "Sila masukkan kata laluan untuk terus",
        "Please enter your registered mobile number.":
          "Sila masukkan nombor telefon bimbit anda yang didaftarkan.",
        "Failed to login. Please try again later.":
          "Gagal log masuk. Sila cuba sebentar lagi.",
        "Internal server error.": "Ralat pelayan dalaman.",
        "Could not fetch member profile. Please try again later.":
          "Tidak dapat mencari profil ahli. Sila cuba sebentar lagi.",
        "Please enter your mobile number":
          "Sila masukkan nombor telefon bimbit anda",
        "An OTP is sent to your mobile number":
          "OTP akan dihantar ke nombor telefon bimbit anda",
        "We couldn't send an OTP. Please try again later.":
          "Kami tidak dapat menghantar OTP. Sila cuba sebentar lagi.",
        "Send OTP": "Hantar semula OTP",
        Proceed: "Teruskan",
        Cancel: "Batal",
        "Please enter your mobile number to continue":
          "Sila masukkan nombor telefon bimbit anda untuk terus",
        "Please enter valid mobile number.":
          "Sila masukkan nombor telefon bimbit yang sah",
        "Change Password": "Ubah Kata Laluan",
        "Change password": "Ubah Kata Laluan",
        "New password": "Kata laluan baru",
        "Confirm new password": "Sahkan kata laluan baru",
        "*Should be 8-32 characters with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character":
          "*Hendaklah 8-32 aksara dengan sekurang-kurangnya 1 huruf besar, 1 huruf kecil, 1 nombor dan 1 aksara khas",
        "*Passwords do not match": "*Kata laluan tidak sepadan",
        "Please enter a password to continue.":
          "Sila masukkan kata laluan untuk terus",
        "Please enter the confirm password to continue.":
          "Sila masukkan kata laluan sahkan untuk terus",
        "Could not load the required data :(":
          "Tidak dapat memuatkan data yang diperlukan :(",
        "Could not login. Please try again later.":
          "Tidak dapat log masuk. Sila cuba sebentar lagi.",
        "Could not send an OTP to your mobile number. Please try again later.":
          "Tidak dapat menghantar OTP ke nombor telefon bimbit anda. Sila cuba sebentar lagi.",
        "Could not reset password. Please try again later.":
          "Tidak dapat menetapkan semula kata laluan. Sila cuba sebentar lagi.",
        "Could not get your profile information. Please try again later.":
          "Tidak dapat cari maklumat profil anda. Sila cuba sebentar lagi.",
        "Invalid OTP": "OTP tidak sah",
        "Already have an account?": "Sudah mempunyai akaun?",
        "Enter new password here": "Masukkan kata laluan baharu di sini",
        "Enter confirm password here": "Masukkan sahkan kata laluan di sini",
        "Password reset successfully": "Berjaya menetapkan semula kata laluan",
        OK: "OKEY",
        "Thank you for completing the registration for Yamaha E-Services, Warranty and GenBlu Rewards ! We're excited to have you on board to our community. To get the most out of your membership, please download our app and log in with your mobile number. It's the best way to access all the features and benefits we offer.":
          "Terima kasih kerana melengkapkan pendaftaran untuk Yamaha E-Services, Waranti dan GenBlu Rewards ! Kami teruja untuk mendapatkan anda menyertai komuniti kami. Untuk memanfaatkan keahlian anda sepenuhnya, sila muat turun aplikasi kami dan log masuk menggunakan nombor telefon anda. Ini adalah cara terbaik untuk mengakses semua ciri dan faedah yang kami tawarkan.",
        // SignUp
        "Let's Get Started!": "Mari kita mulakan!",
        "User information": "Maklumat pengguna",
        "Set username": "Tetapkan Nama Pengguna",
        "Set password": "Tetapkan kata laluan",
        "Confirm Password": "Sahkan kata laluan",
        "Enter confirm password": "Masukkan kata laluan sahkan",
        "Please enter username": "Sila masukkan nama pengguna",
        "Please enter the correct password":
          "Sila masukkan kata laluan yang betul",
        "Passwords do not match": "Kata laluan tidak sepadan",
        "Should be at least 8 alphanumeric characters":
          "Hendaklah sekurang-kurangnya 8 aksara abjad angka",
        "Should be 8-32 characters consisting of at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character":
          "Hendaklah 8-32 aksara yang terdiri daripada sekurang-kurangnya 1 huruf besar, 1 huruf kecil, 1 nombor dan 1 aksara khas\n",
        "Could not sign up. Please try again later.":
          "Tidak dapat mendaftar. Sila cuba sebentar lagi.",
        "Username successfully set up.": "nama pengguna berjaya didaftarkan",
        Ok: "Ok",
        OK: "Ok",
        "Are you a Yamaha bike owner?": "Adakah anda pemilik motosikal Yamaha?",
        "Plate number": "Nombor plat",
        "*Bike plate numbers are between 2 and 8 characters.":
          "*Nombor plat adalah antara 2 dan 16 aksara.",
        "IC number": "nombor IC",
        "*Should be at most 35 digits": "*Maksima 35 digit",
        OR: "ATAU",
        "Yes I’m a Yamaha Motorcycle Owner":
          "Ya, saya seorang Pemilik Motosikal Yamaha",
        "I do not have Yamaha Motorcycle":
          "Saya tidak mempunyai Motosikal Yamaha.",
        "Enter plate number here": "Masukkan nombor plat di sini",
        "Enter IC number here": "Masukkan nombor kad pengenalan di sini",
        "Please enter plate number.": "Sila masukkan nombor plat.",
        "Please enter IC number": "Sila masukkan nombor kad pengenalan.",
        "Please enter valid plate number.":
          "Sila masukkan nombor plat yang sah.",
        "Please enter valid IC number": "Sila masukkan nombor IC yang sah.",
        "Sorry, we are unable to find information about the motorcycle with the given plate number and IC number which might be due to:\n 1.Motorcycle is registered with JPJ before 2016.\n 2.Motorcycle ownership has changed (second owner).\n 3.Interchanged Plate Number.\n Do 'Provide more details' for Genblu Rewards admin verification and approval,\n thank you.":
          "Maaf, kami tidak dapat mencari maklumat mengenai motosikal dengan nombor plat dan nombor IC yang diberikan, mungkin disebabkan oleh:\n 1.Motosikal didaftarkan dengan JPJ sebelum 2016.\n 2.Pemilik motosikal telah bertukar (pemilik kedua) .\n 3.Nombor Plat Bertukar.\n Lakukan 'Sediakan butiran lanjut' untuk pengesahan dan kelulusan pentadbir Genblu Rewards,\n terima kasih.",
        "Sorry, we are unable to find information about the motorcycle with the given plate number and IC number which might be due to:":
          "Maaf, kami tidak dapat mencari maklumat mengenai motosikal dengan nombor plat dan nombor IC yang diberikan, mungkin disebabkan oleh:",
        "Motorcycle is registered with JPJ before 2016.":
          "Motosikal didaftarkan dengan JPJ sebelum 2016.",
        "Motorcycle ownership has changed (second owner).":
          "Pemilik motosikal telah bertukar (pemilik kedua).",
        "Interchanged Plate Number.": "Bertukar Nombor Plat.",
        "Do 'Provide more details' for Genblu Rewards admin verification and approval,":
          "Lakukan 'Sediakan butiran lanjut' untuk pengesahan dan kelulusan pentadbir Genblu Rewards,",
        "thank you.": "terima kasih.",
        "Failed to verify from JPJ database. Please try again later.":
          "Gagal mengesahkan daripada pangkalan data JPJ. Sila cuba sebentar lagi.",
        "You have not completed the previous sign up. Please sign Up again.":
          "Anda belum melengkapkan pendaftaran sebelumnya. Sila daftar semula.",
        "Bike plate number should be between 2 to 16 alphanumeric characters and should not contain any special characters or space":
          "*Nombor plat motosikal hendaklah antara 2 hingga 16 aksara abjad angka dan tidak boleh mengandungi sebarang aksara khas atau ruang.",
        "IC number should be at most 35 characters and should not contain any special characters or space":
          "*Nombor IC hendaklah maksima 35 aksara dan tidak boleh mengandungi sebarang aksara khas atau ruang.",
        "Bike details uploaded. We will notify you as soon as it is validated by our team.":
          "Butiran motosikal dimuat naik. Kami akan memberitahu anda sebaik sahaja ia disahkan.",
        "Let's Register": "Jom Daftar",
        "VOC copy": "salinan VOC",
        Submit: "Hantar",
        Back: "belakang",
        "Add Bike Details": "Tambah Butiran Motosikal",
        "Engine number": "Nombor enjin",
        "Chassis number": "Nombor casis",
        "Model number": "Nombor model",
        "Take Photo": "Ambil gambar",
        "Choose Photo": "Pilih gambar",
        "Could not get URL": "Tidak dapat URL",
        "error in profile image upload:": "ralat dalam muat naik imej profil:",
        "Image uploaded": "Imej berjaya dimuat naik",
        "Please enter engine number.": "Sila masukkan nombor enjin.",
        "Please enter chassis number.": "Sila masukkan nombor casis.",
        "Please select model number.": "Sila pilih nombor model.",
        "Please upload at least 1 VOC image.":
          "Sila muat naik sekurang-kurangnya 1 imej VOC.",
        "Could not update bike details. Please try again later.":
          "Tidak dapat mengemas kini butiran motosikal. Sila cuba sebentar lagi.",
        Apply: "Mohon",
        "Could not get model number. Please try again later.":
          "Tidak dapat nombor model. Sila cuba sebentar lagi.",
        "Could not verify from JPJ. Please try again later.":
          "Tidak dapat mengesahkan daripada JPJ. Sila cuba sebentar lagi.",
        "Could not fetch member profile. Please try again later.":
          "Tidak dapat mengambil profil ahli. Sila cuba sebentar lagi.",
        "Could not update the vehicle information. Please try again later.":
          "Tidak dapat mengemas kini maklumat motosikal. Sila cuba sebentar lagi.",
        "Could not fetch your profile. Pplease try again later.":
          "Tidak dapat mencari profil anda. Sila cuba sebentar lagi.",
        "Sorry we could not update your subscription. Please try again later.":
          "Maaf kami tidak dapat mengemas kini langganan anda. Sila cuba sebentar lagi.",
        "I have read, understood and accepted the":
          "Saya telah membaca, memahami dan menerima ",
        "Terms & Conditions": "Terma & Syarat",
        and: "dan",
        "Create Username": "Cipta nama pengguna",
        "Do you own a Yamaha motorcycle?":
          "Adakah anda memiliki motosikal Yamaha?",
        Yes: "Ya",
        No: "tidak",
        "Motorcycle details": "Butiran motosikal",
        "Provide your Yamaha Motorcycle details.":
          "Berikan butiran Motosikal Yamaha anda.",
        "IC no./Passport no.": "No. IC/No pasport.",
        Skip: "Langkau",
        "Sorry, We couldn't find information for the provided bike plate and IC number.":
          "Maaf, Kami tidak dapat mencari maklumat untuk plat basikal dan nombor IC yang disediakan.",
        "This might be because:": "Ini mungkin kerana:",
        "The motorcycle was registered before 2016.":
          "Motosikal itu telah didaftarkan sebelum 2016.",
        "There may have been changes in ownership.":
          "Mungkin terdapat perubahan dalam pemilikan.",
        "Interchanged plate number.": "Bertukar nombor plat.",
        "JPJ data is not updated.": "Data JPJ tidak dikemas kini.",
        "Details not found": "Butiran tidak ditemui",
        Continue: "Teruskan",

        // Profile setup
        "Let's know you!": "Jom kenali awak!",
        "Welcome to user information details":
          "Selamat datang ke butiran maklumat pengguna",
        "Upload photo": "Muat naik gambar",
        "Activate account": "Aktifkan akaun",
        "Username Login": "Log Masuk Nama Pengguna",
        "Phone Login": "Log Masuk Telefon",
        "Social Login": "Log Masuk Sosial",
        "Full name": "Nama penuh",
        Gender: "Jantina",
        "Date of birth": "Tarikh lahir",
        "Email address": "Alamat emel",
        "Mobile number": "Nombor telefon",
        "Address line 1": "Alamat Baris 1",
        "Address line 2": "Alamat 2",
        Postcode: "Pos Kod",
        "Post code": "Pos Kod",
        State: "Negeri",
        City: "Bandar",
        "Save as": "Simpan sebagai",
        "Please enter a valid email address":
          "Sila masukkan alamat emel yang sah.",
        "Please enter post code": "Sila masukkan pos kod.",
        "We could not send a verification link to your email Address. Please try again later.":
          "Kami tidak dapat menghantar pautan pengesahan ke Alamat e-mel anda. Sila cuba sebentar lagi.",
        "Could not upload photo.": "Tidak dapat memuat naik gambar",
        "Please enter your name": "Sila masukkan nama anda",
        "Please enter your date of birth": "Sila masukkan tarikh lahir anda",
        "Please enter your gender": "Sila masukkan jantina anda",
        "Please enter mobile number": "Sila masukkan nombor telefon bimbit",
        "Please enter your address": "Sila masukkan alamat anda.",
        "Please enter your post code": "Sila masukkan pos kod anda.",
        "Please enter a valid post code to get state":
          "Sila masukkan pos kod yang sah untuk mendapatkan negeri.",
        "Please enter a valid post code to get city":
          "Sila masukkan pos kod yang sah untuk mendapatkan bandar.",
        "Please select your address type": "Sila pilih jenis alamat anda",
        "We have sent a verification link to your email address.":
          "Kami telah menghantar pautan pengesahan ke alamat e-mel anda.",
        "We have sent an OTP to your mobile number and a verification link to your email address.":
          "Kami telah menghantar OTP ke nombor mudah alih anda dan pautan pengesahan ke alamat e-mel anda.",
        "We have sent an OTP to your mobile number.":
          "Kami telah menghantar OTP ke nombor telefon bimbit anda.",
        "OTP sent successfully": "OTP berjaya dihantar",
        "Member does not exist": "Ahli tidak wujud",
        "This member does not exist": "Ahli ini tidak wujud",
        "We could not send an verification link to you email address. Please try again later.":
          "Kami tidak dapat menghantar pautan pengesahan kepada alamat e-mel anda. Sila cuba sebentar lagi.",
        "We could not send an OTP to your registered mobile number. Please try again later.":
          "Kami tidak dapat menghantar OTP ke nombor telefon bimbit anda yang berdaftar. Sila cuba sebentar lagi.",
        "Could not get the state and city for the post code.":
          "Tidak dapat mendapatkan negeri dan bandar untuk poskod.",
        "Sign Up Again": "Daftar Semula",
        "You have not completed the previous sign up. Please sign Up again.":
          "Anda belum melengkapkan pendaftaran sebelumnya. Sila daftar semula.",
        Logout: "Log keluar",
        "Enter full name here": "Masukkan nama penuh di sini",
        "-Select gender-": "-Pilih jantina-",
        "-Choose date-": "-Pilih tarikh-",
        "Enter email address here": "Masukkan alamat e-mel di sini",
        "Enter mobile number here": "Masukkan nombor telefon bimbit di sini",
        "Enter address line 1": "Masukkan alamat baris 1",
        "Enter address line 2": "Masukkan alamat baris 2",
        "Enter post code here": "Masukkan kod pos di sini",
        Home: "Utama",
        Done: "Selesai",
        "Enter the OTP sent to your mobile phone":
          "Masukkan OTP yang dihantar ke telefon bimbit anda",
        Sec: "kedua",
        "Didn't receive an OTP?": "Tidak menerima OTP?",
        Resend: "Hantar semula",
        "Enter the OTP sent to user mobile phone":
          "Masukkan OTP yang dihantar ke telefon bimbit anda",
        "Please enter OTP to continue.": "Sila masuk OTP untuk terus.",
        "We are unable to process your request. Please try again later.":
          "Kami tidak dapat memproses permintaan anda. Sila cuba sebentar lagi.",
        "Invalid OTP.": "OTP tidak sah.",
        "We have resent an OTP to your registered mobile number.":
          "Kami telah menghantar OTP ke nombor telefon bimbit yang anda daftarkan.",
        "We could not send an OTP. Please try again later.":
          "Kami tidak dapat menghantar OTP. Sila cuba sebentar lagi.",
        "Please enter a valid OTP": "Sila masukkan OTP yang sah",
        "Congratulations! Bike assigned successfully.":
          "Tahniah! Motosikal berjaya ditugaskan.",
        "Scan the QR code available on your card":
          "Imbas kod QR yang tersedia pada kad anda",
        "Membership card number": "Nombor Kad Ahli",
        "-This will auto fill from the QR code-":
          "-Ini akan mengisi secara automatik daripada kod QR-",
        "Could not scan QR code. Please try again later.":
          "Tidak dapat mengimbas kod QR. Sila cuba sebentar lagi.",
        "Please scan a QR code to proceed.": "Sila imbas kod QR untuk terus",
        "Card added successfully.": "Kad berjaya ditambah",
        "Card number must have 12 digits. Please scan valid card.":
          "Nombor kad mesti ada 12 digit. Sila imbas kad yang sah.",
        "Please scan a valid membership QR code.":
          "Sila imbas kod QR ahli yang sah.",
        "You already have this card": "Anda sudah mempunyai kad ini",
        "Member with this card details already exist.Please scan a unique card.":
          "Ahli dengan butiran kad ini sudah wujud. Sila imbas kad unik.",
        "You already have a card": "Anda sudah mempunyai kad",
        "Could not add card. Please try again later.":
          "Tidak dapat menambah kad. Sila cuba sebentar lagi.",
        "Could not generate your virtual card. Please try again later.":
          "Tidak dapat menjana kad maya anda. Sila cuba sebentar lagi.",
        " We could not send the OTP. Please try again later":
          " Kami tidak dapat menghantar OTP. Sila cuba sebentar lagi",
        "Failed to update profile.Please try again later":
          "Gagal mengemas kini profil. Sila cuba sebentar lagi",
        "Could not send the OTP. Please try again later":
          "Tidak dapat menghantar OTP. Sila cuba sebentar lagi",
        "Could not get the state and city for this post code. Please try again later.":
          "Tidak dapat carian negeri dan bandar untuk poskod ini. Sila cuba sebentar lagi.",
        "Could not generate a virtual card. Please try again later.":
          "Tidak dapat menjana kad maya. Sila cuba sebentar lagi.",
        "Could not fetch your profile. Please try again later.":
          "Tidak dapat mengambil profil anda. Sila cuba sebentar lagi.",
        Shop: "Kedai",
        Vouchers: "Baucar",
        Redeem: "Tebus",
        Profile: "Profil",
        Male: "Lelaki",
        Female: "Perempuan",
        "Add Motorcycle details": "Tambah butiran motosikal",
        "Add now": "Tambah sekarang",
        "Email ID": "ID emel",
        "Save address as": "Simpan alamat sebagai",
        "Personal Details": "Maklumat peribadi",

        // Home
        "My reward points": "Mata ganjaran saya",
        "Transaction history": "Sejarah transaksi",
        "No notifications are available to show":
          "Tiada notifikasi tersedia untuk ditunjukkan",
        "Scan the event QR code": "Imbas kod QR acara",
        "Campaign / Event Code": "Kod Kempen / Acara",
        "-This will auto-fill from the QR Code-":
          "-Ini akan mengisi secara automatik daripada Kod QR-",
        "*Please enter membership number in format 386-4173-270":
          "*Sila masukkan nombor ahli dalam format 386-4173-270",
        "Check in": "Daftar masuk",
        Transactions: "Transaksi",
        Date: "Tarikh",
        Description: "Penerangan",
        Amount: "Jumlah",
        "Bonus points earned on purchase of new bike":
          "Mata bonus diperoleh apabila membeli motosikal baharu",
        "No transactions to show": "Tiada transaksi untuk ditunjukkan",
        "Notification Details": "Butiran Notifikasi",
        "Yamaha motorbike servicing at 50% off for the first 100 customers":
          "Servis motosikal Yamaha pada potongan 50% untuk 100 pelanggan pertama",
        "Transaction Details": "butiran transaksi",
        "Order Details": "Maklumat Pesanan",
        "Event Details": "Butiran Acara",
        "Points Validity": "Kesahan Mata",
        "Valid Till": "Sah Sehingga",
        Points: "mata",
        "No points break up to show. Please earn some points.":
          "Tiada butiran mata untuk ditunjukkan. Sila perolehi mata ganjaran",
        "Congratulations! You are checked in.":
          "Tahniah! Anda telah daftar masuk.",
        "Retail Banking:": "Perbankan Runcit:",
        "Congratulations! Your subscription is activated.":
          "Tahniah! Langganan anda diaktifkan.",
        "Subscription fee:": "Yuran langganan:",
        "Online Banking": "Perbankan atas talian",
        "Pay with": "Bayar dengan",
        "(Current and Savings Account)": "(Akaun Semasa dan Simpanan)",
        "Amount should be between RM 1 and RM 30,000":
          "Jumlah hendaklah antara RM 1 dan RM 30,000",
        "Select:": "Pilih:",
        "Select Bank": "Pilih Bank",
        "By clicking on the “Proceed button , you hereby agree with FPX’s Terms & Conditions.":
          "Dengan mengklik pada “butang Teruskan , anda dengan ini bersetuju dengan Terma & Syarat FPX.",
        "Powered by": "Dijana oleh",
        "Your membership has expired, Please activate your subscription plan or add a new Yamaha motorcycle to continue, Thank you.":
          "Keahlian anda telah tamat, Sila aktifkan pelan langganan anda atau tambah motosikal Yamaha baru untuk teruskan, Terima kasih.",
        "Activate Subscription": "Aktifkan Langganan",
        "Add Bike": "Tambah motosikal",
        Close: "Tutup",
        "Could not get your points balance. Please try again later.":
          "Tidak dapat mendapatkan baki mata anda. Sila cuba sebentar lagi.",
        "Subscription payment": "Bayaran langganan",
        "Do the payment": "Buat pembayaran",
        "Could not fetch subscription details. Please try again later.":
          "Tidak dapat carian butiran langganan. Sila cuba sebentar lagi.",
        "No Subscription can be found": "Tiada Langganan boleh ditemui",
        "Scan the membership card number": "Imbas nombor kad ahli",
        "Failed to scan the QR Code.": "Gagal mengimbas Kod QR.",
        "Please scan the OR code to get the event/campaign code":
          "Sila imbas kod ATAU untuk mendapatkan kod acara/kempen",
        "Please scan the membership card number or enter membership number or mobile number to continue.":
          "Sila imbas nombor kad ahli atau masukkan nombor ahli atau nombor telefon bimbit untuk terus.",
        "Invalid Url": "URL tidak sah",
        "Could not get notifications. Please try again later.":
          "Tidak dapat menerima pemberitahuan. Sila cuba sebentar lagi.",
        "Bonus points": "Mata bonus",
        "Genblu purchase": "Pembelian Genblu",
        "Regular purchase": "Pembelian biasa",
        "Campaign check in": "Daftar masuk kempen",
        "Campaign purchase": "Pembelian kempen",
        "Points awarded": "Mata diberikan",
        "Points redeemed": "Mata ditebus",
        "Points deducted": "Mata dipotong",
        "Points expired": "Mata tamat tempoh",
        "Points accrued": "Mata terakru",
        "Transaction type": "Jenis transaksi",
        "Awarded on": "Dianugerahkan pada",
        "Expired on": "Tamat tempoh pada",
        "Used on": "Digunakan pada",
        "Merchant Remarks": "Ucapan Pedagang",
        "View Attachment": "Lihat Lampiran",
        Summary: "Ringkasan",
        "Card used for this transaction":
          "Kad yang digunakan untuk transaksi ini",
        "Awarded by": "Dianugerahkan oleh",
        "Reference no.": "No rujukan.",
        "Could not get campaign information. Please try again later.":
          "Tidak boleh mendapatkan maklumat kempen. Sila cuba sebentar lagi.",
        "Sorry we could not fetch transaction list. Please try again later.":
          "Maaf kami tidak dapat mengambil senarai transaksi. Sila cuba sebentar lagi.",
        "Sorry we could not fetch member points balance. Please try again later.":
          "Maaf kami tidak dapat mengambil baki mata ahli. Sila cuba sebentar lagi.",
        "Failed to mark notification as read.":
          "Gagal menandakan pemberitahuan sebagai dibaca.",
        "Could not check in this event. Please try again later.":
          "Tidak dapat mendaftar masuk acara ini. Sila cuba sebentar lagi.",
        "Sorry you have exhausted check in limit for the event":
          "Maaf anda telah kehabisan had daftar masuk untuk acara itu",
        "Sorry you are not allowed to check in to this event.":
          "Maaf anda tidak dibenarkan mendaftar masuk ke acara ini.",
        "This event is not active.": "Acara ini tidak aktif.",
        "Sorry check in limit has been exceeded for this event.":
          "Maaf had daftar masuk telah melebihi untuk acara ini.",
        "You have already checked in to this event.":
          "Anda telah pun mendaftar masuk ke acara ini.",
        "Could not fetch transaction information. Please try again later.":
          "Tidak dapat mengambil maklumat transaksi. Sila cuba sebentar lagi.",
        "Could not assign bike. Please try again later.":
          "Tidak dapat menetapkan motosikal. Sila cuba sebentar lagi.",
        "Please select bank to proceed.": "Sila pilih bank untuk terus",
        "Oops! Failed to fetch banks.": "Op! Gagal ambil bank.",
        "Membership extended validity:": "Kesahan dilanjutkan keahlian:",
        "Membership validity:": "Kesahan keahlian:",
        Extend: "Lanjutkan",
        Reactivate: "Aktifkan semula",
        "Congratulations! Your membership validity extended successfully":
          "Tahniah! Kesahan keahlian anda berjaya dilanjutkan",
        "Failed to update!": "Gagal mengemas kini!",
        "Membership Number": "Nombor keahlian",
        "Add new bike": "Tambah basikal baru",
        "Bike Details": "Butiran basikal",
        "No bikes added": "Tiada motosikal ditambah",
        "Please add your Yamaha motorcycle details to enjoy more privileges.":
          "Sila tambah butiran motosikal Yamaha anda untuk menikmati lebih banyak keistimewaan.",
        "Apparels & Merchandise": "Pakaian & Barangan",
        "GenBlu Café": "Kafe GenBlu",
        "E-Service Booking": "E- Tempahan Perkhidmatan",
        "Yamaha Motor Malaysia": "Yamaha Motor Malaysia",
        "Our Merchants": "Pedagang Kami",
        Extend: "Lanjutkan",
        Active: "Aktif",
        Reactivate: "Aktifkan semula",
        Activate: "Aktifkan",

        // Shop
        "Go to payment": "Pergi ke pembayaran",
        "Shop Explore Now": "Beli Jelajah Sekarang",

        // Redemptions
        "My Redemption": "Penebusan Saya",
        Categories: "Kategori",
        "No Products Available": "Tiada Produk Tersedia",
        "Stock currently unavailable": "Stok tidak tersedia buat masa ini",
        "Unit Points": "Mata Unit",
        "Add to cart": "Tambah ke troli",
        "Proceed to checkout": "Teruskan ke pembayaran",
        "Shopping Cart": "Troli Beli Belah",
        Bag: "Beg",
        Address: "Alamat",
        Order: "Pesanan",
        "Move to Wishlist": "Beralih ke Senarai Hajat",
        Remove: "Alih keluar",
        "Total Amount": "Jumlah keseluruhan",
        "No items are added to cart.": "Tiada item ditambahkan pada troli.",
        Wishlist: "Senarai hajat",
        "Move to bag": "Pindah ke beg",
        "No items are added to wishlist.":
          "Tiada item ditambahkan ke senarai hajat.",
        "Transaction ID": "ID Transaksi",
        Qty: "Kuantiti",
        "Order Placed": "Pesanan Diletakkan",
        "Place Order": "Letakkan Pesanan",
        "Need help?": "Perlukan bantuan?",
        "Shipping Address": "Alamat Penghantaran",
        "View Receipt": "Lihat Resit",
        "Select Size": "Pilih Saiz",
        Done: "Selesai",
        "Congratulations!": "tahniah!",
        "your order has been successfully placed":
          "pesanan anda telah berjaya dibuat",
        "Continue shopping": "Terus membeli-belah",
        "Filter by category": "Tapis mengikut kategori",
        Clear: "Jelas",
        Accessories: "Aksesori",
        "This variation does not exist. Please choose some other variation.":
          "Variasi ini tidak wujud. Sila pilih beberapa variasi lain.",
        "Require ": "Memerlukan ",
        " points to place order": " mata untuk membuat pesanan",
        "Insufficient balance": "Baki tidak mencukupi",
        "Quantity available in stock:": "Kuantiti tersedia dalam stok:",
        "Remove from cart": "Alih keluar dari troli",
        "Are you sure you want to delete address":
          "Adakah anda pasti mahu memadamkan alamat",
        "Please select at least single product to proceed.":
          "Sila pilih sekurang-kurangnya satu produk untuk terus.",
        "Item successfully added to cart.":
          "Item berjaya ditambahkan pada troli.",
        "Mobile:": "Telepon:",
        "Other address": "Alamat lain",
        " Points": " Mata",
        "Sorry! Unable to download the file.":
          "Maaf! Tidak dapat memuat turun fail.",
        "Could no get specific product detail. Please try again later.":
          "Tidak boleh mendapatkan butiran produk tertentu. Sila cuba sebentar lagi.",
        "Sorry could not fetch shopping cart list. Please try again later":
          "Maaf tidak dapat mengambil senarai troli beli-belah. Sila cuba sebentar lagi",
        "Sorry we could not add the product to shopping cart. Please try again later":
          "Maaf kami tidak dapat menambahkan produk pada troli beli-belah. Sila cuba sebentar lagi",
        "Sorry we could not remove the product from shopping cart. Please try again later":
          "Maaf kami tidak dapat mengeluarkan produk daripada troli beli-belah. Sila cuba sebentar lagi",
        "Sorry could not fetch wishlist. Please try again later":
          "Maaf tidak dapat mengambil senarai hajat. Sila cuba sebentar lagi",
        "Sorry could not add to wishList. Please try again later":
          "Maaf tidak dapat menambah senarai hajat. Sila cuba sebentar lagi",
        "Sorry could not remove from wishList. Please try again later":
          "Maaf tidak dapat mengalih keluar daripada senarai hajat. Sila cuba sebentar lagi",
        "We could not send your order details to server. Please try again later.":
          "Kami tidak dapat menghantar butiran pesanan anda kepada pelayan. Sila cuba sebentar lagi.",
        "We could not fetch categories. Please try again later.":
          "Kami tidak dapat mengambil kategori. Sila cuba sebentar lagi.",
        "Sorry could not apply the filter. Please try again later":
          "Maaf tidak dapat menggunakan penapis. Sila cuba sebentar lagi",
        "Product added to wishlist successfully":
          "Produk berjaya ditambahkan ke senarai hajat",
        "Product added to bag successfully":
          "Produk berjaya ditambahkan dalam beg",
        "No product wishlisted": "Tiada produk yang disenaraikan",
        "Bag Detail": "Butiran Beg",

        // Vouchers
        Voucher: "Baucar",
        "My Vouchers": "Baucar Saya",
        Available: "Tersedia",
        Redeemed: "Ditebus",
        Expired: "Tamat tempoh",
        "E-service": "E-perkhidmatan",
        "E-Voucher": "Baucar Digital",
        "Service Labour Charge Discount Voucher":
          "Baucar Diskaun Caj Upah Servis",
        "Plate no.": "Plat no.",
        RewardedOn: "Diberi Ganjaran",
        Items: "Barang",
        "E-servicing": "E-servis",
        "Fully Redeemed": "Ditebus Sepenuhnya",
        "No vouchers are available to show":
          "Tiada baucar tersedia untuk ditunjukkan",
        "Voucher Details": "Butiran Baucar",
        "Yamaha E-Service Voucher": "Baucar E-Service Yamaha",
        "Read more": "Baca lagi",
        "Redeem now": "Tebus sekarang",
        "Redeemed On": "Ditebus pada",
        "Voucher code": "Kod baucar",
        Discount: "Diskaun",
        "Voucher value": "Nilai baucar",
        "Max value": "Nilai maksima",
        "Model name": "Nama model",
        "Rewarded date": "Tarikh ganjaran",
        "Expiry date": "Tarikh luput",
        "Redeemed date": "Tarikh ditebus",
        "Expired date": "Tarikh luput",
        "Sorry we could not fetch E-service coupons for you. Please try again later.":
          "Maaf kami tidak dapat mengambil kupon E-perkhidmatan untuk anda. Sila cuba sebentar lagi.",
        "Sorry we could not fetch generic voucher for you. Please try again later.":
          "Maaf kami tidak dapat mengambil baucar generik untuk anda. Sila cuba sebentar lagi.",
        "Sorry we could not fetch details for this specific voucher. Please try again later.":
          "Maaf kami tidak dapat mengambil butiran untuk baucar khusus ini. Sila cuba sebentar lagi.",
        "Voucher Code": "Kod Baucar",
        "Awarded On": "Diberikan pada",
        "Expires On": "Tamat tempoh pada",
        "Expired On": "Tamat tempoh pada",
        "Voucher Value": "Nilai baucar",
        "Max Value": "Nilai maksima",

        // Profile
        "Manage membership": "Urus Keahlian",
        Membership: "Keahlian",
        "Current plan duration": "Tempoh rancangan semasa",
        "Amount paid": "Amaun yang dibayar",
        "Membership expires on": "Keahlian tamat tempoh pada",
        "View membership cards": "Lihat kad keahlian",
        "Events Attended": "Acara yang Dihadiri",
        Event: "Peristiwa",
        "Points Earned": "Mata Diperolehi",
        "Manage User": "Urus pengguna",
        "Terms & conditions": "Terma & Syarat",
        "Manage addresses": "Urus alamat",
        "Events attended": "Acara yang dihadiri",
        "Are you sure you want to logout": "Adakah anda pasti mahu log keluar",
        "Could not fetch member profile. Please try again later":
          "Tidak dapat mengambil profil ahli. Sila cuba sebentar lagi",
        "Your membership expired on": "Keahlian anda tamat tempoh pada",
        "Your membership expires on": "Keahlian anda tamat tempoh pada",
        "Please activate your membership": "Sila aktifkan keahlian anda",
        "Sorry we could not fetch events attended for you right now. Please try again later":
          "Maaf kami tidak dapat mengambil acara yang dihadiri untuk anda sekarang. Sila cuba sebentar lagi",
        "Sorry we could not fetch membership date. Please try again later.":
          "Maaf kami tidak dapat mengambil tarikh keahlian. Sila cuba sebentar lagi.",

        // Support
        Support: "Sokongan",
        "Raise a Ticket": "Naikkan Tiket",
        "My Tickets": "Tiket Saya",
        Attachments: "Lampiran",
        "Subject :": "Subjek :",
        "Created On :": "Dicipta pada :",
        Approved: "Diluluskan",
        "No Tickets Available": "Tiada Tiket Tersedia",
        Details: "Butiran",
        Subject: "Subjek",
        Category: "Kategori",
        "Please provide the subject related to your issue":
          "Sila berikan subjek yang berkaitan dengan isu anda",
        "Please select the Category to proceed":
          "Sila pilih Kategori untuk terus",
        "Please provide the description for your issue":
          "Sila berikan penerangan untuk isu anda",
        "Could not upload": "Tidak dapat memuat naik",
        "Your ticket has been raised successfully.":
          "Tiket anda telah berjaya dinaikkan.",
        "Failed to raise your ticket. Try again":
          "Gagal menaikkan tiket anda. Cuba lagi",
        "Please enter the subject line": "Sila masukkan baris subjek",
        "Please provide the description": "Sila berikan penerangan.",
        "General Inquiry": "Siasatan Am",
        "Errors/Bug Reporting": "Ralat/Pelaporan Pepijat",
        "My Redemptions": "Penebusan",
        "User Manager": "Pengurus Pengguna",
        "E-Service Coupon and Vouchers": "Kupon dan Baucar E-Perkhidmatan",
        "Membership and Subscription": "Keahlian dan Langganan",
        "Points and Transactions": "Mata dan Transaksi",
        "Campaigns and Events": "Kempen dan Acara",
        "Motorcycle Information": "Maklumat Motosikal",
        "User Account": "Pengguna akaun",
        "Ticket Id": "Id Tiket",
        Status: "Taraf",
        "Created On": "Dicipta pada",
        "Comment by Yamaha": "Ulasan oleh Yamaha",
        "Could not fetch Tickets. Please try again later.":
          "Tidak dapat mengambil Tiket. Sila cuba sebentar lagi.",
        "Could not fetch Ticket Details. Please try again later.":
          "Tidak dapat mengambil Butiran Tiket. Sila cuba sebentar lagi.",
        "Failed to raise your ticket. Try again.":
          "Gagal menaikkan tiket anda. Cuba lagi.",
        Open: "Buka",
        Resolved: "Selesai",
        Closed: "Tutup",
        Pending: "Yang belum selesai",
        "Attach an image/screenshot of the issue(Optional)":
          "Sila lampirkan imej/tangkapan skrin mengenai isu (Pilihan)",

        // Settings
        Settings: "tetapan",
        "Edit profile": "Kemas Profil",
        "Edit Password": "Ubah Kata Laluan",
        "Old password": "Kata laluan lama",
        "*Should be at least 8 characters":
          "*Hendaklah sekurang-kurangnya 8 aksara",
        Tier: "Tahap",
        "Owner Silver": "Pemilik Perak",
        "Update Account": "Kemas Kini Akaun",
        "Setup Username": "Tetapkan Nama Pengguna",
        "Edit Username": "Ubah Nama Pengguna",
        "Notification settings": "Tetapan Notifikasi",
        "Request account deletion": "Minta pemadaman akaun",
        "Change Language": "Tukar bahasa",
        "We are sad that you want to leave us, but please note that account deletion is irreversible.":
          "Kami sedih kerana anda mahu meninggalkan kami, tetapi sila ambil perhatian bahawa pemadaman akaun tidak dapat dipulihkan.",
        "Username successfully updated": "Nama pengguna berjaya dikemas kini",
        "Please enter old password.": "Sila masukkan kata laluan lama.",
        "Please enter new password.": "Sila masukkan kata laluan baharu.",
        "Please enter confirm new password.":
          "Sila masukkan sahkan kata laluan baharu.",
        "Please enter your password correctly":
          "Sila masukkan kata laluan anda dengan betul",
        "Please enter your old password correctly":
          "Sila masukkan kata laluan lama anda dengan betul",
        "Password changed successfully": "Kata laluan berjaya ditukar",
        "Are you sure you want to leave, your changes are unsaved.":
          "Adakah anda pasti mahu pergi, perubahan anda tidak disimpan.",
        "Profile updated successfully": "Profil berjaya dikemas kini",
        "Could not send an OTP. Please try again later.":
          "Tidak dapat menghantar OTP. Sila cuba sebentar lagi.",
        "Sorry we could not change your username. Please try again later.":
          "Maaf kami tidak dapat menukar nama pengguna anda. Sila cuba sebentar lagi.",
        "Failed to Delete Account.Please try again later":
          "Gagal Memadam Akaun. Sila cuba sebentar lagi",
        "No bikes added": "Tiada motosikal ditambah",
        "Registration Date": "Tarikh pendaftaran",
        "Reg. Date": "pendaftaran",
        User: "Pengguna",
        "Select the bike": "Pilih motosikal",
        "Have another Yamaha bike?":
          "Anda mempunyai motosikal Yamaha yang lain?",
        "User Information": "Maklumat pengguna",
        "Membership Number or Registered Mobile Number":
          "Nombor Keahlian atau Nombor Telefon Bimbit Berdaftar",
        "Create Address": "Tetapkan Alamat",
        "Set as default address": "Tetapkan sebagai alamat utama",
        "Update Address": "Kemas kini  Alamat",
        "Edit Address": "Kemas kini Alamat",

        // "Model No." : "Nombor model",
        "Remove User": "Alih keluar Pengguna",
        "Bike assignee removed successfully":
          "Pemegang tugasan motosikal berjaya dialih keluar",
        "Approval Pending": "Kelulusan Belum Selesai",
        Rejected: "Ditolak",
        Assigned: "Ditugaskan",
        "Sorry we could not assign this bike, Please try again later.":
          "Maaf kami tidak dapat menetapkan motosikal ini, Sila cuba lagi kemudian.",
        "Sorry we could not delete this bike, Please try again later.":
          "Maaf kami tidak dapat memadamkan motosikal ini, Sila cuba sebentar lagi.",
        "Membership Card": "Kad keahlian",
        "Yamaha Loyalty Membership Card": "Kad Keahlian Yamaha Loyalty",
        "Add Card": "Tambah Kad",
        "No cards are added.": "Tiada kad ditambahkan.",
        "Scan the Qr code available on back side of your card":
          "Imbas kod Qr yang tersedia di bahagian belakang kad anda",
        "-This field will auto fill  QR Code-":
          "-Medan ini akan mengisi Kod QR secara automatik-",
        "Make this as my default card": "Jadikan ini sebagai kad lalai saya",
        "Assign User": "Tugaskan Pengguna",
        Name: "Nama",
        Email: "E-mel",
        Phone: "Telefon",
        "No bike assigned to any other user":
          "Tiada motosikal ditugaskan kepada pengguna lain",
        "Failed to set this card as default.Please try again later.":
          "Gagal menetapkan kad ini sebagai lalai. Sila cuba sebentar lagi.",
        "Manage Address": "Urus Alamat",
        "+ Add Address": "+ Tambah Alamat",
        "Default Address": "Alamat Lalai",
        "Make this as default": "Jadikan ini sebagai pilihan utama",
        "Add New Address": "Tambah Alamat Baharu",
        Mobile: "Telefon Bimbit",
        "Make this as my default address":
          "Jadikan ini sebagai alamat pilihan utama saya",
        "Are you sure you want to delete the address?":
          "Adakah anda pasti mahu memadamkan alamat tersebut?",
        "Please enter a post code": "Sila masukkan kod pos",
        "Address edited successfully.": "Alamat berjaya diedit.",
        "Address added successfully": "Alamat berjaya ditambahkan",
        Setup: "persediaan",
        "*Should be 7-20 alphanumerric characters with maximum 1 dash '-' ":
          "*Sepatutnya 7-20 aksara alfanumerik dengan maksimum 1 sengkang '-' ",
        "*Should be 7-20 alphanumeric characters":
          "*Sepatutnya 7-20 aksara abjad angka",
        "Please enter plate number to continue.":
          "Sila masukkan nombor plat untuk meneruskan.",
        "Provide more details": "Berikan butiran lanjut",
        "Choose model number": "Pilih nombor model",
        "Default card": "Kad lalai",
        "Deafult address": "Alamat deafult",
        "Select a Category": "Pilih kategori",
        Category: "Kategori",
        "Total Points": "Jumlah Mata",
        "Explore now": "Meneroka sekarang",
        "Complete the bike Details Now.":
          "Lengkapkan Butiran basikal Sekarang.",
        Notifications: "Pemberitahuan",
        Search: "Cari",
        "Default address": "Alamat lalai",
        // "Edit Address" : "Edit Alamat",
        "Enter chassis number": "Masukkan nombor casis",
        "Enter engine number": "Masukkan nombor enjin",
        Delete: "Padam",
        "Add New Address": "Tambah Alamat Baharu",
        Save: "Jimat",
        Work: "Kerja",
        Others: "Lain-lain",
        "Enter mobile number": "Masukkan nombor telefon bimbit",
        " (optional)": " (pilihan)",
        "Please specify others": "Sila nyatakan yang lain",
        "Please enter your mobile number to login":
          "Sila masukkan nombor telefon bimbit anda untuk log masuk",
        Time: "Masa",
        "Attach an image/screenshot of the issue":
          "Lampirkan imej/tangkapan skrin isu tersebut",
        "Remove user": "Alih keluar pengguna",
        "Add user": "Tambah pengguna",
        "Plate No.": "No. Plat",
        "Engine No.": "No. Enjin",
        "Chassis No.": "No. Casis",
        "Model No.": "No. Model",
        "Please fill all fields": "Sila isi semua medan",
        "Date of Birth": "Tarikh Lahir",
        "No content in request or mobile number is missing.":
          "Tiada kandungan dalam permintaan atau nombor mudah alih yang hilang.",
        "Invalid Mobile Number!": "Nombor Mudah Alih Tidak Sah!",
        "Member does not exist!": "Ahli tidak wujud!",
        "IC Number": "Nombor IC",
        "Name, mobile number, and IC number can not be empty!":"Nama, nombor telefon bimbit dan nombor IC tidak boleh kosong!",
        "Sorry, this IC number has been used for member registration previously. Please reinstall the app and sign in with your account registered previously. Thank you.":"Maaf, nombor IC ini telah digunakan untuk pendaftaran ahli sebelum ini. Sila pasang semula apl dan log masuk dengan akaun anda yang didaftarkan sebelum ini. terima kasih.",
        "Invalid IC number!":"Nombor IC tidak sah!",
        "IC number should not contain any special characters or space.":"Nombor IC tidak boleh mengandungi sebarang aksara atau ruang khas.",
        "Verify OTP":"Sahkan OTP",
        "Resend OTP after":"Hantar semula OTP selepas",
        "seconds":"saat",
        "Sorry, this IC number has been used for member registration previously. Please reinstall the app and sign in with your account registered previously. Thank you.":"Maaf, nombor IC ini telah digunakan untuk pendaftaran ahli sebelum ini. Sila pasang semula apl dan log masuk dengan akaun anda yang didaftarkan sebelum ini. terima kasih.",
        "Verified Member details can not updated from here, please use the app to update the details.":"Butiran Ahli Disahkan tidak boleh dikemas kini dari sini, sila gunakan aplikasi untuk mengemas kini butiran.",
        "Mobile number is mandatory to create member!":"Nombor mudah alih adalah wajib untuk membuat ahli!",
        "User Already Exist!":"Pengguna Sudah Wujud!",
      },
    },
  },
  lng: "ma",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;
