import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "../../views/LoginComponents/login.css";
import appGalleryIcon from "../../assets/img/yamahaIcons/appGallery.png";
import appStoreIcon from "../../assets/img/yamahaIcons/appleStore.png";
import playStoreIcon from "../../assets/img/yamahaIcons/googlePlayStore.png";
import memberService from "../service/APIService";
import "./DirectMemberShipSignup.css";
import OTPInput from "react-otp-input";

const DirectMembershipSignup = () => {
  let timeLeft = 60;
  const [timeLeftToResend, setTimeLeftToResend] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const  [otpError,setOtpErrr]= useState("");
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP flow
  const [otp, setOtp] = useState("");
  const path = window.location.pathname;
  const signupIndex = path.indexOf("signup");
  let encodedNumber = "";
  if (signupIndex !== -1) {
    // Split the path and get the segments after "signup"
    const segments = path.split("/").slice(signupIndex + 1);
    encodedNumber = segments[0] || ""; // Get the first segment after "signup"
  }
  // const encodedNumber = path.split("/").pop(); // Extract the last part after "/"

  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  // Decode the Base64-encoded string
  const decodedMobileNumber = isBase64(encodedNumber)
    ? atob(encodedNumber)
    : encodedNumber;
  const [data, setData] = useState({
    name: "",
    icNumber:"",
    mobile: decodedMobileNumber,
  });

  useEffect(() => {
    if (decodedMobileNumber) {
      setData((prevData) => ({
        ...prevData,
        mobile: decodedMobileNumber,
      }));
    }
  }, [decodedMobileNumber]);

  const handleChange = (e) => {
    setError("");
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const countdown = () => {
    if (document.getElementById("seconds")) {
      timeLeft--;
      document.getElementById("seconds").innerHTML = String(timeLeft);
      if (timeLeft > 0) {
        setTimeout(countdown, 1000);
      }
      setTimeLeftToResend(timeLeft);
    }
  };

  useEffect(() => {
    setTimeLeftToResend(60)
    setTimeout(countdown, 1000);
  }, [otpSent]);

   const handleSubmit = () => {
    let alphaNumericRegexForICNumber = /^[A-Za-z0-9-]+$/;
    if (data.icNumber &&  (!alphaNumericRegexForICNumber.test(data.icNumber))) {
      setError("IC number should not contain any special characters or space.")
      return
  }
    if (!data.name || !data.icNumber || !data.mobile) {
      setError("Please fill all fields");
      return;
    }
    const body = {
        memberInfo: {
        name: data.name,
        mobileNumber: data.mobile,
        icNumber: data.icNumber,
      },
    };
    setIsLoading(true);
    memberService
      .handleDMSignup(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setOtpSent(true); 
          setError('')
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError((error.response.data.message));
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  const resendOTP = () => {
    setOtp('');
    setOtpErrr('');
    handleSubmit();
    setTimeLeftToResend(60)
    setTimeout(countdown, 1000);
  }
 

  useEffect(()=>{
    setError('');
    setOtpErrr('');
},[otpSent,otp])


  const verifyOtp = () => {
    const body = {
      memberInfo: {
      name: data.name,
      mobileNumber: data.mobile,
      icNumber: data.icNumber,
    },
    otp:otp

  };
  setIsLoading(true);
  memberService
    .handleDMSignup(body)
    .then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        setModalOpen(true);
        setOtpErrr('');
      } else {
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setOtpErrr(error && error.response && error.response.data && error.response.data.message)
    })
    .finally((e) => {
      setIsLoading(false);
    });
  };


  return (
    <>
      <Col lg="5" md="7" className="pb-6 mt-8 mx-auto">
        <Card className="bg-secondary shadow module-border-wrap">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              {!otpSent && (
                <>
                  <FormGroup>
                    <label
                      htmlFor="name"
                      style={{ fontSize: "12px", color: "black" }}
                    >
                      {t("Name")}
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        name="name"
                        placeholder={t("Name")}
                        type="text"
                        value={data.name}
                        onChange={handleChange}
                        style={{ color: "black" }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="icNumber"
                      style={{ fontSize: "12px", color: "black" }}
                    >
                      {t("IC Number")}
                    </label>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <Input
                          name="icNumber"
                          value={data && data.icNumber}
                          placeholder={t("IC number")}
                          autoComplete="new-icNumber"
                          onChange={handleChange}
                          style={{ textTransform: "uppercase", color: "black" }}
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <label
                      htmlFor="mobile"
                      style={{ fontSize: "12px", color: "#8898aa" }}
                    >
                      {t("Mobile number")}
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-mobile-alt"></i>
                          <span style={{ marginLeft: "10px" }}> +60</span>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        style={{ paddingLeft: "10px" }}
                        name="mobile"
                        placeholder={t("Mobile number")}
                        type="number"
                        value={data.mobile}
                        onChange={handleChange}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        readOnly
                      />
                    </InputGroup>
                  </FormGroup>
                </>
              )}
              {  error && (
                <Row className="mt-3">
                  <Col xs="12" className="text-center">
                    <small style={{ color: "red" }}>{t(error)}</small>
                  </Col>
                </Row>
              )}

              {!otpSent && (
                <div className="text-center">
                  {!isLoading && (
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      style={{ width: "75%" }}
                      disabled={!(data.icNumber && data.mobile && data.name)}
                      onClick={handleSubmit}
                    >
                      {t("Send OTP")}
                    </Button>
                  )}
                  {isLoading && <Spinner color="primary" />}
                </div>
              )}
              {otpSent && (
                <>
                  <FormGroup>
                    <label htmlFor="otp" className="dms-signup-otp-header">
                      {t("Enter the OTP sent to your mobile phone")}
                    </label>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      shouldAutoFocus
                      isInputNum={true}
                      renderInput={(props) => (
                        <input
                          {...props}
                          inputMode="numeric"  // Suggests numeric keypad on mobile
                          pattern="[0-9]*"  
                          onKeyDown={(e) => {
                            if (
                              !/[0-9]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            const paste = (e.clipboardData || window.clipboardData).getData("text");
                            if (!/^\d+$/.test(paste)) {  // Allow only digits
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                      containerStyle="otp-container"
                      inputStyle="otp-box"
                    />
                  </FormGroup>
                  <div style={{ margin: '10px', display: 'flex',justifyContent:'center', alignItems:'center', gap:'2px' }}>
                {/* <small style={{ marginTop: '10px' }}>{t("Didn't receive an OTP?")}</small> */}
                {timeLeftToResend > 0 && <div style={{ display: 'flex', marginTop: '10px' }}><small style={{ color: 'grey', display: 'flex'  }}>{t("Resend OTP after" )} <div id="clock">
                  <span id="seconds" style={{ marginLeft: '2px' }}> 60 </span>
                 </div>  <span style={{ marginLeft: '2px' }}> {t("seconds")}</span></small></div>
                }
                {timeLeftToResend === 0 && <div style={{ display: 'flex', marginTop: '10px' }}>
                  <small onClick={() => resendOTP()} style={{ cursor: 'pointer' , fontWeight: 900}}>{t("Resend")}</small>
                </div>}
              </div>
                  <div className="text-center">
                    {!isLoading && (
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        style={{ width: "75%" }}
                        onClick={verifyOtp}
                        disabled={otp.length !== 6} 
                      >
                        {t("Verify OTP")}
                      </Button>
                    )}
                    {isLoading && <Spinner color="primary" />}
                  </div>
                </>
                
              )}
                {setOtpSent && otpError && (
                <Row className="mt-3">
                  <Col xs="12" className="text-center">
                    <small style={{ color: "red" }}>{t(otpError)}</small>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={modalOpen}
        toggle={() => {setModalOpen(!modalOpen); setOtpSent(false)}}
        centered
      >
        <ModalHeader
          toggle={() => {setModalOpen(!modalOpen); setOtpSent(false)}}
          style={{ height: "0px" }}
        ></ModalHeader>
        <ModalBody style={{ textAlign: "center", paddingTop: "0px" }}>
          <p>
            {t(
              "Thank you for completing the registration for Yamaha E-Services, Warranty and GenBlu Rewards ! We're excited to have you on board to our community. To get the most out of your membership, please download our app and log in with your mobile number. It's the best way to access all the features and benefits we offer."
            )}
          </p>
          <p>
            <div className="success_signup_dialogBox">
              <a
                href="https://play.google.com/store/apps/details?id=com.yamahamotors.genblu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playStoreIcon}
                  alt="App Gallery"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                    paddingRight: "3px",
                  }}
                />
              </a>
              <a
                href="https://apps.apple.com/app/genblu-rewards/id1634646726"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreIcon}
                  alt="Apple Store"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                    paddingRight: "3px",
                  }}
                />
              </a>
              <a
                href="https://appgallery.huawei.com/app/C108354571"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appGalleryIcon}
                  alt="App Gallery"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                  }}
                />
              </a>
            </div>
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DirectMembershipSignup;
